<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import axios from 'axios'

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
  },
  created() {
      console.log("请求接口")
      axios.get("http://localhost:8081/ebook/list").then(res=>{
          console.log(res)
      })
  //      this.fetchTutorials(); //组件创建时获取数据
  // },
  //   methods:{
  //       fetchTutorials(){
  //           axios.get("http://localhost:8081/ebook/list")//替换成你的API接口
  //               .then(response =>{
  //                   if(response.data.success){
  //                       this.content =response.data.content;//待返回的content赋值到data
  //                   } else {
  //                       console.error('Failed to fetch tutorials: ',response.data.message);
  //                   }
  //               })
  //               .catch(error => {
  //                   console.error('API result failed: ',error);
  //               })
  //
  //       }
    }
});
</script>
